 <template>
  <el-dialog
    top="1vh"
    title="运费结算"
    width="1050px"
    :visible.sync="is_show_in_page"
  >
    <el-form class="form_ordinary" label-width="97px">
      <div class="big_tit" style="margin: 0">打款数据</div>
      <div class="tab1_inner">
        <el-form-item
          class="el_form_item"
          label="货主信息"
          style="width: 588px"
        >
          <el-input
            class="el_inner_width"
            v-model="shipper_info_text"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          class="el_form_item"
          label="货主付款"
          style="width: 388px"
        >
          <el-input
            class="el_inner_width"
            v-model="this_payed_text"
            disabled
          ></el-input>
        </el-form-item>
      </div>
      <div class="tab1_inner">
        <el-form-item
          class="el_form_item"
          label="承运人信息"
          style="width: 588px"
        >
          <el-input
            class="el_inner_width"
            v-model="truck_owner_info_text"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          class="el_form_item"
          label="承运人收款"
          style="width: 388px"
        >
          <el-input
            class="el_inner_width"
            v-model="this_settled_text"
            disabled
          ></el-input>
        </el-form-item>
      </div>

      <div class="big_tit">承运人收款卡</div>
      <div class="tab1_inner">
        <el-table
          :data="bank_card_list"
          :border="true"
          :stripe="true"
          size="small"
          height="250px"
        >
          <el-table-column label="银行卡号信息">
            <template slot-scope="scope">
              <div>
                {{ scope.row.card_num }} / {{ scope.row.bank_cname }} /
                {{ scope.row.bank_ename }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="银行卡归属人信息及关系">
            <template slot-scope="scope">
              <div>
                {{ scope.row.card_user_name
                }}{{
                  scope.row.card_user_tel
                    ? " / " + scope.row.card_user_tel
                    : " / 无联系方式"
                }}{{
                  scope.row.relation ? " / 关系:" + scope.row.relation : ""
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="支付类型">
            <template slot-scope="scope">
              <div>
                {{ tord_payed_info.this_pay_type_text }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="银行出款状态">
            <template slot-scope="scope">
              <div>
                {{ settle_status_text }}
              </div>
              <!-- <el-button @click="Refresh()" size="mini" type="text"
                >状态刷新</el-button
              > -->
            </template>
          </el-table-column>
          <!-- 操作行 -->
          <el-table-column label="操作" width="140px" align="center">
            <template slot-scope="scope">
              <!-- <el-button  size="mini" type="text">确认操作</el-button>
							<el-button @click="cash_settle_open(scope.row)" size="mini" type="text">上传凭证</el-button> -->
              <el-button
                @click="cash_settle_open(scope.row)"
                size="mini"
                type="text"
                >人工打款</el-button
              >
              <el-button
                @click="
                automatic(scope.row, tord_payed_info.truck_owner_info.name,this_settled_text)"
                size="mini"
                type="text"
                >徽商银行-发起汇款</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-form>

    <!-- 出金界面 -->
    <cashSettleCreat
      @cash_settle_end="cash_settle_close"
      :data="settle_creat.data"
      :is_show="settle_creat.is_show"
    ></cashSettleCreat>
  </el-dialog>
</template>

<script>
import cashSettleCreat from "./cash_settle_creat.vue";
export default {
  props: {
    tord_payed_info: Object,
    is_show: Number,
    settle_status_text:String,
    ids:"",
  },
  components: {
    cashSettleCreat,
  },
  data() {
    return {
      name: "放放放",
      //页面是否显示
      is_show_in_page: false,

      //可用银行卡列表
      bank_card_list: [],

      //货主信息
      shipper_info_text: "",

      //货主付款
      this_payed_text: "",

      //收款人字符串
      truck_owner_info_text: "",

      //打款金额字符串
      this_settled_text: "",

      //出金界面
      settle_creat: {
        is_show: 0, //是否显示
        data: {}, //支付数据
      },
    };
  },
  watch: {
    is_show(new_data) {
      if (new_data == 0) {
        this.is_show_in_page = false;
      } else {
        this.is_show_in_page = true;
        this.init();
      }
    },
  },
  methods: {
    // 网银自动打款
    automatic(item, name, money) {
      this.$confirm(
        "即将向" +name +"的银行卡" +item.card_num +"打款" + money +"，请确认",
        "提示",
        {
          distinguishCancelAndClose: true,
          confirmButtonText: "确认",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          this.$my.net.req({
            data: {
              mod: "truck_tord_real_time",
              ctr: "out_cash_admin_auto_hsbank",
              id: this.tord_payed_info.id,
              bank_card_num: this.bank_card_list.card_num,
            },
            callback: () => {
            this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});
            },
          });
        })
        .catch((action) => {
        });
    },
    //状态刷新
    Refresh(item) {
      this.$my.net.req({
        data: {
          mod: "truck_tord_real_time",
          ctr: "out_cash_admin_info",
          id: this.ids,
        },
        callback: (data) => {
          this.tord_payed_info.bank_trade_status = data.bank_trade_status
        },
      });
    },
    //打开打款界面
    cash_settle_open(bankcard) {
      //置入数据
      this.settle_creat.data = {
        ra_num: this.tord_payed_info.ra_num,
        truck_tord_num: this.tord_payed_info.truck_tord_num,
        bankcard: bankcard,
        this_settled: this.tord_payed_info.this_settled,
      };

      //打开界面
      this.settle_creat.is_show++;
    },
    cash_settle_close() {
      //关闭打款界面

      //打款界面关闭后
      this.settle_creat.is_show = 0;

      //清空打款界面数据
      this.settle_creat.data = {};

      //关闭当前界面
      this.is_show_in_page = false;

      //通知上个界面
      this.$emit("settle_end", "1");
    },

    //页面初始化
    init() {
      //收款人字符串
      this.shipper_info_text =
        this.tord_payed_info.shipper_info.name +
        " / " +
        this.tord_payed_info.shipper_info.tel +
        " / " +
        this.tord_payed_info.shipper_info.company_name;

      //打款金额字符串
      this.this_payed_text = this.tord_payed_info.this_payed + "元";

      //收款人字符串
      this.truck_owner_info_text =
        this.tord_payed_info.truck_owner_info.name +
        " / " +
        this.tord_payed_info.truck_owner_info.tel;

      //打款金额字符串
      this.this_settled_text = this.tord_payed_info.this_settled + "元";

      //读取车主的可用提现银行卡
      this.$my.net.req({
        data: {
          mod: "app_user",
          ctr: "bankcard_list_admin",
          check_status: 2,
          read_type: "simple", //normal simple
          user_num: this.tord_payed_info.truck_owner_num,
          payed_id: this.tord_payed_info.id,
        },
        callback: (data) => {
          //置入可用银行卡列表
          this.bank_card_list = data.list;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el_form_item {
  margin: 0;
}
.big_tit {
  background-color: #eee;
  padding: 10px 20px;
  color: #666;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
}
.tab1_inner {
  padding: 15px 15px 0 15px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.el_inner_width {
  width: 94%;
}

.form_ordinary {
  padding: 10px 0;
  overflow-y: auto;
}
</style>